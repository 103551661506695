// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#terms {
  align-items: center;
  background-color: #0f131a;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
  height: 100vh;
  width: 100%;
}
section#terms nav-bar {
  position: absolute;
  width: 100%;
  z-index: 16;
}
section#terms .go-to-top {
  position: fixed;
  bottom: 5%;
  right: 15%;
  color: #76797D;
}
section#terms .go-to-top:hover {
  color: #0681b1;
}
section#terms .go-to-top.show {
  display: block;
}
section#terms section#terms_content {
  background-color: #1B2027;
  border-radius: 40px;
  height: auto;
  margin-top: calc(66px + 4rem);
  max-width: 1110px;
  padding: 3rem 4rem;
  padding-bottom: 4rem;
  width: 100%;
}
section#terms section#terms_content div.circle {
  background: rgba(6, 129, 177, 0.5);
  bottom: 90%;
  filter: blur(335px);
  height: 150px;
  left: 100%;
  overflow: hidden;
  position: relative;
  width: 150px;
}

@media only screen and (max-width: 480px) {
  section#terms section#terms_content {
    margin-top: calc(66px + 4rem);
    padding: 0.75rem 1rem;
    width: calc(100% - 2rem);
  }
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/pages/legal/terms/terms.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,mBAAA;EACA,yBD6CuB;EC5CvB,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;EACA,WAAA;AAIF;AAFE;EACE,kBAAA;EACA,WAAA;EACA,WAAA;AAIJ;AADE;EACE,eAAA;EACA,UAAA;EACA,UAAA;EACA,cAAA;AAGJ;AAAE;EACE,cAAA;AAEJ;AACE;EACE,cAAA;AACJ;AAEE;EACE,yBDmBoB;EClBpB,mBAAA;EACA,YAAA;EACA,6BAAA;EACA,iBAAA;EACA,kBAAA;EACA,oBAAA;EACA,WAAA;AAAJ;AAEI;EACE,kCAAA;EACA,WAAA;EACA,mBAAA;EACA,aAAA;EACA,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AAAN;;AAKA;EAEI;IACE,6BAAA;IACA,qBAAA;IACA,wBAAA;EAHJ;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
