import { autoinject } from 'aurelia-framework';

@autoinject()
export class Terms {
  message: string;

  
  constructor() {
    this.message = 'Hello world';
  }
}
    