// Module
var code = `<template>
  <require from="./privacy.scss"></require>
  <require from="../../../components/nav-bar/nav-bar"></require>
  <require from="../../../components/footer-bottom/footer-bottom"></require>
  <section id="privacy" class="flex-column">
    <nav-bar class="backdrop-blur"></nav-bar>
    <div class="title flex-column">
      <h3>MyBanka</h3>
      <h1>Privacy policy</h1>
    </div>
    <div class="privacy_policy flex-column">
      <p>MyBanka et De Buck Technologies SRL ne stockent ni ne commercialisent aucune de vos données</p>
      <p>MyBanka et De Buck Technologies SRL ne stockent ni ne commercialisent aucune de vos données</p>
      <p>MyBanka et De Buck Technologies SRL ne stockent ni ne commercialisent aucune de vos données</p>
      <p>MyBanka et De Buck Technologies SRL ne stockent ni ne commercialisent aucune de vos données</p>
      <p>MyBanka et De Buck Technologies SRL ne stockent ni ne commercialisent aucune de vos données</p>
      <p>MyBanka et De Buck Technologies SRL ne stockent ni ne commercialisent aucune de vos données</p>
    </div>
    <footer-bottom></footer-bottom>
  </section>
</template>
`;
// Exports
export default code;